import moment from "moment"
import "moment/locale/ru"
import { DateFormatType } from "../types/dateTypes"


export const dateFormat = {
    /** время в нужном формате с учетом временной зоны */
    timestampToString: (milliseconds: number, format: DateFormatType = "HH:mm:ss") => {
        return moment(milliseconds).locale("ru").format(format)
    },
    /** время в нужном формате без учета временной зоны */
    timestampToUTCString: (milliseconds: number, format: DateFormatType = "HH:mm:ss") => {
        return moment(milliseconds).utc().locale("ru").format(format)
    },
    /** сдвиг временной зоны пользователя в секундах, для Екатеринбурга будет 18000 */
    getTimezone: () => {
        return new Date().getTimezoneOffset() * -60
    }
}
