import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "../store"
import { logging } from "../../utils/logging"
import { Nullable } from "../../types/otherTypes"
import { tempStorage } from "../tempStorage"
import { setEmail, signIn } from "./authSlice"
import { getMe } from "./userSlice"


const title = "THUNK"

const initialState = {
    isDarkTheme: true,
    initialized: false,
    appIsLoading: true,
    globalError: null as Nullable<string>,
}
type AppState = typeof initialState

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setInitializedSuccess(state: AppState) {
            state.initialized = true
        },
        setAppIsLoading(state: AppState, action: PayloadAction<boolean>) {
            state.appIsLoading = action.payload
        },
        setGlobalError(state: AppState, action: PayloadAction<Nullable<string>>) {
            state.globalError = action.payload
        },

        resetAppState(state: AppState) {
            state.globalError = null
        },
    },
})

export const {
    setAppIsLoading,
    resetAppState,
} = appSlice.actions

export default appSlice.reducer


// начальная инициализация приложения
export const initializeApp = (): AppThunk => async (dispatch) => {
    const subtitle = "APP INIT"
    logging.add({title, subtitle, message: "start"})

    const email = tempStorage.getEmail()
    dispatch(setEmail(email))

    // dispatch(getMe())

    dispatch(appSlice.actions.setInitializedSuccess())
}
