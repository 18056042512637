import React from "react"
import s from "./header.module.css"
import { NavLink } from "react-router-dom"
import { useAppSelector } from "../../../hooks/redux"
import { emailSelector, isSignedInSelector } from "../../../store/selectors/authSelector"


export const Header = () => {

    const email = useAppSelector(emailSelector)
    const isSignedIn = useAppSelector(isSignedInSelector)

    return (
        <div className={s.wrapper}>
            <div className={s.header}>
                <NavLink to={"/"} className={s.button}>Главная</NavLink>

                <>
                    {
                        (isSignedIn && email) ? (
                            <NavLink to={"profile/"} className={s.button}>{email}</NavLink>
                        ) : (
                            <NavLink to={"login/"} className={s.button}>Вход / Регистрация</NavLink>
                        )
                    }
                </>
            </div>
        </div>
    )
}
