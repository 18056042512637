import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "../store"
import { appSlice } from "./appSlice"
import { IGetTokenResponse } from "../../types/api/authAPITypes"
import { API } from "../../api/api"
import { appConfig } from "../../configs/appConfig"
import { logging } from "../../utils/logging"
import { handleError } from "../../utils/handleError"
import { Nullable } from "../../types/otherTypes"
import { tempStorage } from "../tempStorage"
import { IUpdateProfileAvatarRequest } from "../../types/api/userTypes"


const title = "THUNK"

export type TUserInfo = {
    user_id: Nullable<string>
    email: Nullable<string>
    name: Nullable<string>
    surname: Nullable<string>
    lastname: Nullable<string>
    is_worker: boolean
    phone: Nullable<string>
    image: Nullable<string>
}

const initialState = {
    userInfo: {
        email: null,
        image: null,
        is_worker: false,
        lastname: null,
        name: null,
        phone: null,
        surname: null,
        user_id: null,
    } as TUserInfo,
    userInfoIsLoading: false,
    userInfoError: null as Nullable<string>,
}
type UserState = typeof initialState

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserInfo(state: UserState, action: PayloadAction<Partial<TUserInfo>>) {
            state.userInfo = {
                ...state.userInfo,
                ...action.payload,
            }
        },
        setUserInfoIsLoading(state: UserState, action: PayloadAction<boolean>) {
            state.userInfoIsLoading = action.payload
        },
        setUserInfoError(state: UserState, action: PayloadAction<Nullable<string>>) {
            state.userInfoError = action.payload
        },

        resetAuthState() {
            return initialState
        }
    }
})

export const {
    setUserInfo,
    resetAuthState,
} = userSlice.actions

export default userSlice.reducer


// получение данных текущего пользователя
export const getMe = (): AppThunk => async (dispatch, getState) => {
    const subtitle = "GET ME"
    logging.add({title, subtitle})

    dispatch(userSlice.actions.setUserInfoIsLoading(true))

    try {
        const response = await API.user.getMe()

        if (typeof response === "object" && response.user_id) {
            dispatch(userSlice.actions.setUserInfo(response))
        }
    } catch (err: unknown) {
        const message = handleError(err)
        logging.add({
            title,
            subtitle,
            message: "Ошибка при получении данных пользователя",
            result: message
        }, "error")
        dispatch(userSlice.actions.setUserInfoError(message))
    } finally {
        dispatch(userSlice.actions.setUserInfoIsLoading(false))
    }
}

// обновление фото профиля пользователя
export const updateProfileAvatar = ({ photoFile }: IUpdateProfileAvatarRequest): AppThunk => async (dispatch, getState) => {
    const subtitle = "UPDATE AVATAR"
    logging.add({title, subtitle})

    try {
        let response = await API.user.updateProfileAvatar({ photoFile })

        if (response.info) {
            dispatch(getMe())
        }
    } catch (err: unknown) {
        const message = handleError(err)
        logging.add({
            title,
            subtitle,
            message: "Ошибка при обновлении фото профиля пользователя",
            result: message
        }, "error")
        dispatch(userSlice.actions.setUserInfoError(message))
    } finally {
        // dispatch(userSlice.actions.setUserInfoIsLoading(false))
    }
}
