import s from "./footer.module.css"
import React from "react"


export function Footer() {
    return (
        <div className={s.footer}>
            Директор - Наилька Равильевич
        </div>
    )
}
