import { RootState } from "../store"
import { createSelector } from "@reduxjs/toolkit"


export const authIsLoadingSelector = (state: RootState) => state.auth.isLoading
export const isSignedInSelector = (state: RootState) => state.auth.isSignedIn
export const emailSelector = (state: RootState) => state.auth.email
export const loginSelector = (state: RootState) => state.auth.login

const sessionIdSelector = (state: RootState) => state.auth.sessionId
export const sessionIdReselector = createSelector(sessionIdSelector, sessionId => sessionId)

export const userCreatedSuccessSelector = (state: RootState) => state.auth.userCreatedSuccess

// loadings
export const signInIsLoadingSelector = (state: RootState) => state.auth.signInIsLoading
export const signUpIsLoadingSelector = (state: RootState) => state.auth.signUpIsLoading

// errors
// export const authErrorsSelector = (state: RootState) => state.auth.error
export const signInErrorSelector = (state: RootState) => state.auth.signInError
export const signUpErrorSelector = (state: RootState) => state.auth.signUpError
