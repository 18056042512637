import s from "./main.module.css"
import { handleError } from "../../../utils/handleError"
import { API } from "../../../api/api"
import React,{ useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../../hooks/redux"
import { isSignedInSelector } from "../../../store/selectors/authSelector"


export function Main() {

    // const isSignedIn = useAppSelector(isSignedInSelector)
    //
    // const navigate = useNavigate()
    //
    // useEffect(() => {
    //     if (isSignedIn) {
    //         navigate("login/")
    //     }
    // }, [isSignedIn, navigate])
    //
    // if (isSignedIn) {
    //     return null
    // }

    return (
        <main className={s.main}>
            Тут основная инфа
        </main>
    )
}
