import { authAPI } from "./authAPI"
import { userAPI } from "./user"
import { orgsAPI } from "./orgs"


export const API = {
    auth: authAPI,
    user: userAPI,
    orgs: orgsAPI,
}
