import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "../store"
import { appSlice, resetAppState } from "./appSlice"
import { IGetTokenResponse } from "../../types/api/authAPITypes"
import { API } from "../../api/api"
import { appConfig } from "../../configs/appConfig"
import { logging } from "../../utils/logging"
import { handleError } from "../../utils/handleError"
import { Nullable } from "../../types/otherTypes"
import { tempStorage } from "../tempStorage"
import { resetAuthState } from "./userSlice"


const SESSION_PROLONGATION_INTERVAL = appConfig.auth.sessionProlongationInterval
const title = "THUNK"

type TErrorAndLoadingPayload<T> = {
    signIn?: T
    signUp?: T
}

const initialState = {
    // token: null as Nullable<string>,
    email: null as Nullable<string>,
    login: null as Nullable<string>,
    isSignedIn: false,
    sessionId: null as Nullable<string>,
    isLoading: false,
    error: null,

    userCreatedSuccess: false,

    // loadings
    signInIsLoading: false,
    signUpIsLoading: false,

    // errors
    signInError: null as Nullable<string>,
    signUpError: null as Nullable<string>,
}
type LoginState = typeof initialState

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        // setToken(state: LoginState, action: PayloadAction<Nullable<string>>) {
        //     state.token = action.payload
        // },
        setEmail(state: LoginState, action: PayloadAction<Nullable<string>>) {
            state.email = action.payload
        },
        setLogin(state: LoginState, action: PayloadAction<Nullable<string>>) {
            state.login = action.payload
        },
        setIsSignedIn(state: LoginState, action: PayloadAction<boolean>) {
            state.isSignedIn = action.payload
        },
        setSessionID(state: LoginState, action: PayloadAction<Nullable<string>>) {
            state.sessionId = action.payload
        },
        setUserCreatedSuccess(state: LoginState) {
            state.userCreatedSuccess = true
        },
        // setIsLoading(state: LoginState, action: PayloadAction<Record<"signIn" | "signUp", boolean>>) {
        // setIsLoading(state: LoginState, action: PayloadAction<{ signIn?: boolean, signUp?: boolean }>) {
        setIsLoading(state: LoginState, action: PayloadAction<TErrorAndLoadingPayload<boolean>>) {
            // state.isLoading = action.payload
            const {signIn, signUp} = action.payload
            if (signIn !== undefined) state.signInIsLoading = signIn
            if (signUp !== undefined) state.signUpIsLoading = signUp
        },
        // setIsError(state: LoginState, action: PayloadAction<{ signIn?: Nullable<string>, signUp?: Nullable<string> }>) {
        setError(state: LoginState, action: PayloadAction<TErrorAndLoadingPayload<Nullable<string>>>) {
            const {signIn, signUp} = action.payload
            if (signIn !== undefined) state.signInError = signIn
            if (signUp !== undefined) state.signUpError = signUp
        },
        clearAuthError(state: LoginState) {
            // сброс всех ошибок
            state.signInError = null
            state.signUpError = null
        },

        resetAuthState() {
            return initialState
        },
    },
})

export const {
    clearAuthError,
    setEmail,
    setIsLoading,
    setError,
} = authSlice.actions

export default authSlice.reducer


// вход в аккаунт
export const signIn = ({email, password}: { email: string, password: string }): AppThunk => async (dispatch, getState) => {
    const subtitle = "SIGN IN"
    logging.add({title, subtitle})

    dispatch(authSlice.actions.setIsLoading({ signIn: true }))
    dispatch(authSlice.actions.setError({signIn: null}))

    try {
        const response = await API.auth.getToken({
            email,
            password,
        })

        const token = response.access_token

        console.log("token", token)

        if (token) {
            // dispatch(authSlice.actions.setToken(token))
            tempStorage.setToken(token)

            dispatch(authSlice.actions.setEmail(email))
            tempStorage.setEmail(email)

            dispatch(authSlice.actions.setIsSignedIn(true))
        } else {
            // TODO: ПРОВЕРЯТЬ НА ИСТЕЧЕНИЕ СЕССИИ
            new Error("Не удалось войти")
        }

    } catch (err: unknown) {
        const message = handleError(err)
        logging.add({title, subtitle, message: "Ошибка входа", result: message}, "error")
        dispatch(authSlice.actions.setError({signIn: message}))
    } finally {
        dispatch(authSlice.actions.setIsLoading({ signIn: false }))
        // dispatch(appSlice.actions.setAppIsLoading(false))
    }
}

// создание аккаунта
export const signUp = ({email}: {email: string}): AppThunk => async (dispatch, getState) => {
    const subtitle = "CREATE"
    logging.add({title, subtitle})

    dispatch(authSlice.actions.setIsLoading({ signUp: true }))
    dispatch(authSlice.actions.setError({signUp: null}))

    try {
        const response = await API.user.createUser({
            email,
        })

        // console.log("response:", response)

        if (response.email) {
            dispatch(authSlice.actions.setUserCreatedSuccess())
            dispatch(authSlice.actions.setEmail(response.email))
        }
    } catch (err: unknown) {
        const message = handleError(err)
        logging.add({
            title,
            subtitle,
            message: "Ошибка при создании пользователя",
            result: message
        }, "error")
        dispatch(authSlice.actions.setError({signUp: message}))
    } finally {
        dispatch(authSlice.actions.setIsLoading({ signUp: false }))
    }
}


// выйти из аккаунта
export const signOut = (): AppThunk => async (dispatch, getState) => {
    const subtitle = "SIGN OUT"
    logging.add({title, subtitle})

    dispatch(resetAppState())
    dispatch(authSlice.actions.resetAuthState())
    dispatch(resetAuthState())
    tempStorage.resetAll()
}


// сброс пароля — придет письмо с ключом
export const initResetPassword = ({email}: { email: string }): AppThunk => async (dispatch, getState) => {
    const subtitle = "INIT RESET PASS"
    logging.add({title, subtitle})

    // dispatch(authSlice.actions.setIsLoading(true))

    try {
        const response = await API.auth.initResetPassword({
            email,
        })

        console.log("response", response)



    } catch (err: unknown) {
        const message = handleError(err)
        logging.add({title, subtitle, message: "Ошибка инициализации сброса пароля", result: message}, "error")
    } finally {

    }
}


// сброс пароля по ключу из письма
export const resetPasswordByKey = ({ key }: { key: string }): AppThunk => async (dispatch, getState) => {
    const subtitle = "RESET PASS BY KEY"
    logging.add({title, subtitle})

    // dispatch(authSlice.actions.setIsLoading(true))

    try {
        const response = await API.auth.resetPasswordByKey({
            key,
        })

        console.log("response", response)



    } catch (err: unknown) {
        const message = handleError(err)
        logging.add({title, subtitle, message: "Ошибка при попытке сбросить пароль по ключу", result: message}, "error")
    } finally {

    }
}
