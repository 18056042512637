/**
 * общие ошибки ответа сервера
 */

/** общие ошибки ответов */
export interface IApiGeneralError {
    Error?: string
    ErrorCode?: EnumApiGeneralErrorCodes
}

/** коды общих ошибок */
export enum EnumApiGeneralErrorCodes {
    UnknownError = 1,
    SessionIdExpiredOrNotExist = 2,
    IncorrectParamValues = 3,
    RequiredParamIsMissing = 4,
    UserDoesNotHaveRights = 5
}

/** ответы, возвращающие "Result: 0 | 1" */
export enum EnumResponseResultNumber {
    fail = 0,
    success = 1
}
