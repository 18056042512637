import React, { lazy, Suspense, useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "./hooks/redux"
import { isAppInitializedSelector } from "./store/selectors/appSelector"
import { initializeApp } from "./store/slices/appSlice"
import s from "./App.module.css"

// TODO: убрать куда надо и сделать ленивость
import { Header } from "./ui/components/header/Header"
import { Main } from "./ui/components/main/Main"
import { Footer } from "./ui/components/footer/Footer"
import { ProtectedRoute } from "./ui/components/ProtectedRoute/ProtectedRoute"
import { isSignedInSelector } from "./store/selectors/authSelector"


const LoginPage = lazy(() => import("./ui/screens/auth/Login"))
const ProfilePage = lazy(() => import("./ui/screens/profile/Profile"))
const NotFoundPage = lazy(() => import("./ui/screens/not_found/NotFound"))


export const App = () => {

    const dispatch = useAppDispatch()

    const isAppInitialized = useAppSelector(isAppInitializedSelector)
    const isSignedIn = useAppSelector(isSignedInSelector)

    useEffect(() => {
        dispatch(initializeApp())
    }, [])

    if (!isAppInitialized) {
        return (
            <h1>Загрузка</h1>
        )
    }

    return (
        <BrowserRouter>
            <div className={s.App}>
                <Header/>

                <div style={{ flex: 1 }}>
                    {/*<Suspense fallback={<h1>Загрузка</h1>}>*/}
                    <Suspense>
                        <Routes>
                            <Route index element={<Main/>}/>
                            <Route path="login" element={<LoginPage/>}/>
                            {/*<Route path="/registration" element={<Registration />} />*/}
                            <Route element={<ProtectedRoute isAllowed={isSignedIn}/>}>
                                <Route path="profile" element={<ProfilePage/>}/>
                            </Route>
                            <Route path="*" element={<NotFoundPage/>}/>
                        </Routes>
                    </Suspense>
                </div>

                <Footer/>
            </div>
        </BrowserRouter>
    )
}
