import axios from "axios"
import { EnumApiGeneralErrorCodes } from "../types/api/apiTypes"
import { logging } from "../utils/logging"
import { appConfig } from "../configs/appConfig"
import { tempStorage } from "../store/tempStorage"


const title = "API"

/** инстанс axios запроса */
export const axiosInstance = axios.create({
    // headers: {
    //     "Content-Type": "application/x-www-form-urlencoded",
    // },
})

/** перехватчик для запросов, который перед каждым запросом получает адрес сервера */
axiosInstance.interceptors.request.use(
    async (config) => {
        const subtitle = "REQUEST"

        config.baseURL = `${appConfig.apiURL}/${appConfig.apiVersion}/`

        if (typeof config.data !== "string") {
            config.data = {
                ...config.data,
            }
        }

        const token = tempStorage.getToken()
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`
        }

        logging.add({title, subtitle, message: config.baseURL + config.url, result: config.data})

        return config
    },
    (error) => {
        const subtitle = "REQUEST"
        logging.add({
            title,
            subtitle,
            message: `${error.config.baseURL}${error.config.url} Ошибка при выполнении запроса`,
            result: error
        }, "error")
        return Promise.reject(error)
    },
)

/** перехватчик для ответов */
axiosInstance.interceptors.response.use(
    (response) => {
        const subtitle = "RESPONSE"

        // здесь обрабатываются все 2xx статус-коды
        const {status, config} = response
        let {data} = response

        logging.add({title, subtitle, message: `${config.baseURL}${config.url}, статус ${status}`, result: data})

        if (data?.ErrorCode === EnumApiGeneralErrorCodes.SessionIdExpiredOrNotExist) {
        }

        return response
    },
    (error) => {
        const subtitle = "RESPONSE"

        // здесь обрабатываются все статус-коды кроме 2xx

        // if (error.response.status === 401) {
        //     // Сессия устарела
        //     store.dispatch(clearAllAppState())
        //     // Действия для обновления сессии, например, показать модальное окно с предложением войти заново
        // } else {
        //     // Обработка других ошибок
        // }

        logging.add({
            title,
            subtitle,
            message: `baseUrl?: ${error?.response?.config?.baseURL}, url?:${error?.response?.config?.url}, status: ${error?.response?.status}, statusText: ${error?.response?.statusText}`,
            result: error
        }, "error")

        logging.add({
            title,
            subtitle,
            message: `Ответ от сервера: ${error?.response?.data?.detail}`,
        }, "error")

        return Promise.reject(error)
        // return Promise.reject({
        //     error,
        //     response: error?.response?.data?.detail,
        // })
        // return error
    },
)
