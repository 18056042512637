import { axiosInstance } from "./apiSettings"
import {
    IGetTokenRequest,
    IGetTokenResponse,
    IInitResetPasswordRequest,
    IInitResetPasswordResponse, IResetPasswordByKeyRequest, IResetPasswordByKeyResponse
} from "../types/api/authAPITypes"


const title = "API"

/** API авторизации */
export const authAPI = {
    /** получение токена пользователя */
    getToken({ email, password }: IGetTokenRequest) {
        return axiosInstance
            .post<IGetTokenResponse>(
                `user/token`,
                {
                    username: email,
                    password,
                },
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    }
                }
            )
            .then(res => res.data)
    },
    /** сброс пароля — придет письмо с ключом */
    initResetPassword({ email }: IInitResetPasswordRequest) {
        return axiosInstance
            .post<IInitResetPasswordResponse>(
                `user/initialization_reset_password`,
                {
                    email,
                }
            )
            .then(res => res.data)
    },
    /** сброс пароля по ключу из письма */
    resetPasswordByKey({ key }: IResetPasswordByKeyRequest) {
        return axiosInstance
            .post<IResetPasswordByKeyResponse>(
                `user/reset_password`,
                {
                    uuid: key,
                }
            )
            .then(res => res.data)
    },
}
