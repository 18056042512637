import {logging} from "../utils/logging"
import { Nullable } from "../types/otherTypes"


const title = "TEMP STORAGE"

export const tempStorage = {
    /** email */
    getEmail() {
        const email = localStorage.getItem("email")
        return email ? JSON.parse(email) : null
    },
    setEmail(email: Nullable<string>) {
        localStorage.setItem("email", JSON.stringify(email))
    },

    /** token */
    getToken() {
        const token = localStorage.getItem("token")
        return token ? JSON.parse(token) : null
    },
    setToken(token: Nullable<string>) {
        localStorage.setItem("token", JSON.stringify(token))
    },

    /** сбросить все */
    resetAll() {
        logging.add({title, subtitle: "ResetAll"})

        this.setEmail(null)
        this.setToken(null)
    }
}
