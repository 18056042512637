import React, { FC, PropsWithChildren } from "react"
import { Navigate, Outlet } from "react-router-dom"


type TProps = {
    isAllowed: boolean
    redirectPath?: string
}

// export const ProtectedRoute = (
export const ProtectedRoute: FC<PropsWithChildren<TProps>> = (
    {
        isAllowed,
        redirectPath = "/",
        children
        // }: PropsWithChildren<TProps>
    }
) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace/>
    }

    return (
        children ? <>{children}</> : <Outlet/>
    )
}
