import {dateFormat} from "./date"
import {appConfig} from "../configs/appConfig"


const MAX_LOG_COUNT = appConfig.logs.maxLogCount
const MAX_LOG_LENGTH = appConfig.logs.maxLogLength
const LOG_SEPARATOR = ";\n"

interface ISaveProps {
    title?: string | "API" | "THUNK" | "HOOK" | "COMPONENT" | "SCREEN"
    subtitle?: string | "REQUEST" | "RESPONSE" | "NATIVE"
    message?: string
    result?: unknown
}

type ConsoleMethods = "log" | "warn" | "error"

const messages: String[] = []

/** объект для работы с логами */
export const logging = {
    /** добавляет лог в массив до перезапуска приложения */
    add: (props: ISaveProps, consoleMethod?: ConsoleMethods, copyToConsole?: boolean) => add(props, consoleMethod ?? "log", copyToConsole ?? true),
    /** возвращает строку с логами с момента последнего запуска приложения по возрастанию времени */
    getLastLaunchLogsByTimeAsc: () => getLastLaunchLogsByTimeAsc(),
    /** возвращает строку с логами с момента последнего запуска приложения по убыванию времени */
    getLastLaunchLogsByTimeDesc: () => getLastLaunchLogsByTimeDesc(),
}

/** форматирует лог */
const getFormattedLog = (props: ISaveProps) => {
    const {
        title = "",
        subtitle = "",
        message = "",
    } = props

    let {result = ""} = props

    const now = Date.now()

    if (result === null) {
        result = "null"
    }

    if (result instanceof Error) {
        result = String(result)
    }

    const logStr = `${dateFormat.timestampToString(now, "YYYY.MM.DD_HH:mm:ss")} - [${title}${title && subtitle ? "|" : ""}${subtitle}] ${message}${result && (": " + JSON.stringify(result))}`
    const trimmedLogStr = (logStr.length > MAX_LOG_LENGTH)
        ? logStr.substring(0, MAX_LOG_LENGTH) + "..."
        : logStr

    return trimmedLogStr + LOG_SEPARATOR
}

/** добавляет лог в массив до перезапуска приложения */
const add = (props: ISaveProps, consoleMethod: ConsoleMethods, copyToConsole: boolean) => {
    const formattedLog = getFormattedLog(props)

    if (copyToConsole) {
        appConfig.isDevMode && console[consoleMethod](formattedLog)
    }

    // зачистка старых логов при превышении лимита строк
    if (messages.length >= MAX_LOG_COUNT) {
        // удалить на 1 больше для нового лога
        messages.splice(0, messages.length - MAX_LOG_COUNT + 1)
    }

    messages.push(formattedLog)
}

/** возвращает строку с логами с момента последнего запуска приложения по возрастанию времени */
const getLastLaunchLogsByTimeAsc = () => {
    return messages.join("")
}

/** возвращает строку с логами с момента последнего запуска приложения по убыванию времени */
const getLastLaunchLogsByTimeDesc = () => {
    return messages.reverse().join("")
}
