import { AnyAction, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit"
import appSlice from "./slices/appSlice"
import authSlice from "./slices/authSlice"
import userSlice from "./slices/userSlice"
import { appConfig } from "../configs/appConfig"


// (!) при добавлении слайса, добавить его очистку в clearAllAppState()
const rootReducer = combineReducers({
    app: appSlice,
    auth: authSlice,
    user: userSlice,
})

const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
    })
}

export const store = setupStore()


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>
