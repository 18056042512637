export const appConfig = {

    // TODO: УБРАТЬ
    isDevMode: true,

    domainURL: "http://serket.ru",
    apiURL: "https://serket.ru:5000/api",
    apiVersion: "v1",
    auth: {
        sessionProlongationInterval: 300000, // 5 минут
    },
    logs: {
        // максимальное количество сохраненных логов
        maxLogCount: 5000,
        // максимальная длина одного лога
        maxLogLength: 2000,
    },
}
