export const handleError = (error: unknown) => {
    let message: string

    if (error instanceof Error) {
        console.error(error.message)
        message = error.message
    } else if (typeof error === "string") {
        console.error(error)
        message = error
    } else {
        console.error(error)
        message = JSON.stringify(error, null, 2)
    }

    return message
}
