import { axiosInstance } from "./apiSettings"
import {
    ICreateUserRequest,
    ICreateUserResponse,
    IDeleteUserRequest,
    IDeleteUserResponse,
    // IGetUserRequest,
    IGetUserResponse, IUpdateProfileAvatarRequest, IUpdateProfileAvatarResponse, IUpdateUserRequest
} from "../types/api/userTypes"
import { IApiGeneralError } from "../types/api/apiTypes"
import * as fs from "fs"
// import qs from "qs"


const title = "API"

/** API пользователей */
export const userAPI = {
    /** создание пользователя */
    createUser(data: ICreateUserRequest) {
        // const encodedData = qs.stringify(data)
        return axiosInstance
            .post<ICreateUserResponse>(
                `user`,
                // encodedData,
                data,
            )
            .then(res => res.data)
    },
    /** получение данных пользователя */
    getMe() {
        return axiosInstance
            .get<IGetUserResponse>(
                `user`,
            )
            .then(res => res.data)
    },
    /** удаление пользователя */
    deleteUser({ userId }: IDeleteUserRequest) {
        return axiosInstance
            .delete<IDeleteUserResponse>(
                `user/${userId}`,
            )
            .then(res => res.data)
    },
    /** обновление данных пользователя */
    updateUser(data: IUpdateUserRequest) {
        return axiosInstance
            .patch<IApiGeneralError>(
                `user`,
                data,
            )
            .then(res => res.data)
    },
    updateProfileAvatar({photoFile}: IUpdateProfileAvatarRequest) {
        return axiosInstance
            .patch<IUpdateProfileAvatarResponse>(
                `user/photo`,
                {
                    file: photoFile,
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
            .then(res => res.data)
    },
}
