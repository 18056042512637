import { axiosInstance } from "./apiSettings"
import {
    ICreateOrgRequest,
    ICreateOrgResponse,
    IDeleteOrgRequest,
    IDeleteOrgResponse,
    IGetOrgRequest,
    IGetOrgResponse,
    IGetOrgsListRequest,
    IGetOrgsListResponse,
    IUpdateOrgRequest,
    IUpdateOrgResponse,
} from "../types/api/orgsTypes"


const title = "API"

/** API авторизаций */
export const orgsAPI = {
    /** создание организации */
    createOrg(data: ICreateOrgRequest) {
        return axiosInstance
            .post<ICreateOrgResponse>(
                `organizations`,
                data,
            )
            .then(res => res.data)
    },
    /** получение списка организаций */
    getOrgsList(data: IGetOrgsListRequest) {
        return axiosInstance
            .get<IGetOrgsListResponse>(
                `organizations`,
            )
            .then(res => res.data)
    },
    /** получение данных организации */
    getOrg({ organization_id }: IGetOrgRequest) {
        return axiosInstance
            .get<IGetOrgResponse>(
                `organizations/${organization_id}`,
            )
            .then(res => res.data)
    },
    /** удаление организации */
    deleteOrg({ organization_id }: IDeleteOrgRequest) {
        return axiosInstance
            .delete<IDeleteOrgResponse>(
                `organizations/${organization_id}`,
            )
            .then(res => res.data)
    },
    /** обновление данных организации */
    updateOrg(data: IUpdateOrgRequest) {
        return axiosInstance
            .patch<IUpdateOrgResponse>(
                `organizations`,
                data,
            )
            .then(res => res.data)
    },
}
